import axios from 'axios';

const baseUrl = 'https://vps-esepkysap.ru/alaiku/core/';

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    'Content-type': 'application/json',
    accept: 'application/json',
  },
});
