import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Phone } from '../../assets/phone.svg';
import { Link } from 'react-router-dom';

const SideBar = () => {
  return (
    <Wrapper>
      <NavItems>
        <li>
          <Link to='/'>Главная</Link>
        </li>
        <li className='about_company_btn relative'>
          <ItemLinks>
            <li>
              <Link to='/about-company'>О компании</Link>
            </li>
            <li>
              <Link to='/vacancies'>Вакансии</Link>
            </li>
          </ItemLinks>
        </li>
        <li>
          <Link to='/blogs'>Блог</Link>
        </li>
        <Contact>
          <div>
            <Phone />
            <a href='tel:0220505001'>0(220) 50 50 01</a>
          </div>
        </Contact>
      </NavItems>
    </Wrapper>
  );
};

export default SideBar;

const Wrapper = styled.div`
  background-color: #fff;
  position: relative;
  z-index: 10000;
  height: 100%;
`;

const ItemLinks = styled.div`
  border-radius: 10px;
  padding: 2px 15px;
  margin-top: 5px;
  li {
    margin-bottom: 5px;
  }

  a {
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    color: red;
  }
`;

const NavItems = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;

  li {
    margin: 0 25px;
    margin-bottom: 20px;
  }
  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #231f20;
  }

  .about_company_btn {
    &:hover {
      ${ItemLinks} {
        display: block;
        :hover {
          display: block;
        }
      }
    }
  }
`;
const Contact = styled.div`
  display: inline-block;
  max-width: 190px;
  margin-bottom: 20px;
  margin-left: 20px;
  div {
    display: flex;
    align-items: center;
    border: 1px solid #0175e0;
    border-radius: 30px;
    padding: 16px;
    cursor: pointer;
  }
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #0175e0;
    margin-left: 16px;
  }
`;
