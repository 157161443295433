import React from "react";
import styled from "styled-components";
const MainSlider1 = ({ item }) => {
  return (
    <Wrapper>
      <WavesBg>
        <img src={item.photo} alt="img" />
      </WavesBg>
    </Wrapper>
  );
};

export default MainSlider1;

const Wrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 600px;
  @media (max-width: 768px) {
    min-height: 200px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
`;

const WavesBg = styled.div`
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    top: 5%;
    z-index: -1;
  }
`;
