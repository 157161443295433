import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchNews, fetchSingleNews } from '../../api/api';
import Layout from '../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';

const Blog = () => {
  const [blog, setBlog] = useState();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const getSingleNews = useCallback(async () => {
    const { data } = await fetchSingleNews(id).then();
    setBlog(data?.data);
  }, [id]);

  const getNews = async () => {
    setLoading(true);
    const { data } = await fetchNews().then();
    setBlogs(data.data?.results);
    setLoading(false);
  };

  useEffect(() => {
    getSingleNews();
    getNews();
  }, [id, getSingleNews]);

  if (loading) return <Loader />;

  return (
    <Layout>
      <Wrapper>
        asdfasdfasdf
        <div className=' mb-10 lg:mr-10'>
          <h1 className='title'>{blog?.headline}</h1>
          <p
            className='text'
            dangerouslySetInnerHTML={{ __html: blog?.text }}
          ></p>
          <div className='img_bg'>
            <img src={blog?.photo} alt='bg' />
          </div>
        </div>
        <div>
          {blogs?.map((item, index) => {
            return (
              <LastNews key={index}>
                <Link to={`/blog/${item?.id}`}>
                  <div className='image'>
                    <img src={item?.photo} alt='img' />
                  </div>
                  <div className='news_title'>{item?.headline}</div>
                </Link>
              </LastNews>
            );
          })}
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Blog;

const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    padding: 60px 50px;
  }
  .img_bg {
    height: 400px;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 24px;
  }
  .text {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 40px;
  }
`;

const LastNews = styled.div`
  a {
    display: flex;
    align-items: center;
  }
  margin-bottom: 20px;
  .news_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  .image {
    height: 100px;
    width: 150px;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
  }
`;
