import React from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";

const Loader = () => {
  return (
    <div className="w-full min-h-screen flex justify-center items-center">
      <Loading
        type="spokes"
        color="blue"
        height={"50px"}
        width={"50px"}
        className="loader"
      />
    </div>
  );
};

export default Loader;

const Loading = styled(ReactLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;
