import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchNews } from '../../api/api';
import Layout from './../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNews = async () => {
    setLoading(true);
    const { data, success } = await fetchNews().then();
    if (success) {
      setBlogs(data.data?.results);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  if (loading) return <Loader />;

  return (
    <Layout>
      <Wrapper className='px-4 py-10 lg:px-36 lg:py-10'>
        <h1 className='title lg:pb-10'>Блог</h1>
        <div className='blogs 2xl:grid 2xl:grid-cols-3 xl:grid xl:grid-cols-3 lg:grid lg:grid-cols-3 md:grid md:grid-cols-3 sm:grid sm:grid-cols-2'>
          {blogs?.length ? (
            blogs?.map((item) => {
              return (
                <BlogItem key={item?.id}>
                  <Link to={`/blog/${item?.id}`}>
                    <div className='image'>
                      <img src={item?.photo} alt='img' />
                    </div>
                    <div className='text'>{item?.headline}</div>
                  </Link>
                </BlogItem>
              );
            })
          ) : (
            <p className='font-bold text-xl text-blue-900'>
              "Нет никакой информации !!!"
            </p>
          )}
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Blogs;

const Wrapper = styled.div`
  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
  }
`;
const BlogItem = styled.div`
  margin: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.36);
  }
  .text {
    padding: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .image {
    height: 300px;

    img {
      border-radius: 20px 20px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
