import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import SideBar from './../SideBar/SideBar';

const Layout = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(false);

    const handleBurger = ()=>{
        setShowSidebar(!showSidebar)
    }

    return (
        <div className="relative">
                 <Navbar handleBurger={handleBurger} showSidebar={showSidebar}/>
            {
                showSidebar && <SideBar  handleBurger={handleBurger}/>
            }
            {children}
            <Footer/>
        </div>
    )
}

export default Layout