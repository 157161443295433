import React from 'react';
import { ReactComponent as MobilePhone } from '../../assets/contacts/mobile-phone.svg';
import { ReactComponent as Location } from '../../assets/contacts/location.svg';
import { ReactComponent as Phone } from '../../assets/phone.svg';
import { ReactComponent as Email } from '../../assets/contacts/email.svg';
import { ReactComponent as Instagram } from '../../assets/social-icons/instagram.svg';
import { ReactComponent as Facebook } from '../../assets/social-icons/facebook.svg';
import styled from 'styled-components';

const FooterInfo = () => {
  return (
    <Info>
      <h1 className='mb-10'>Контакты</h1>
      <div className='text'>Оставайтесь с нами на связи</div>
      <Contacts>
        <div className='flex items-center mb-5'>
          <MobilePhone />
          <a className='ml-2' href='tel:0220505001'>
            0(220) 50 50 01
          </a>
        </div>
        <div className='flex items-center mb-5'>
          <Location />
          <span className='ml-2 text-white'>г. Ош, ул. Джамбула 7, 723503</span>
        </div>
        <div className='flex items-center mb-5'>
          <Phone />
          <a className='ml-2' href='tel:0312252233'>
            0(312) 25 22 33
          </a>
        </div>
        <div className='flex items-center mb-5'>
          <Email />
          <a
            className='ml-2'
            rel='noopener noreferrer'
            href='mailto:office.alaiku@gmail.com'
          >
            office.alaiku@gmail.com
          </a>
        </div>
      </Contacts>
      <div className='text mt-7'>Следите за нами в соцсетях:</div>
      <SocialLinks>
        <div className='flex items-center mb-5'>
          <Instagram />
          <a
            className='ml-2'
            rel='noopener noreferrer'
            href='https://www.instagram.com/alaiku.company'
          >
            alaiku.kg
          </a>
        </div>
        <div className='flex items-center mb-5'>
          <Facebook />
          <a
            className='ml-2'
            rel='noopener noreferrer'
            href='https://www.facebook.com/%D0%90%D0%BB%D0%B0%D0%B9%D0%BA%D1%83-101892091463406/'
          >
            alaiku.kg
          </a>
        </div>
      </SocialLinks>
    </Info>
  );
};

export default FooterInfo;

const Info = styled.div`
  @media (max-width: 768px) {
    padding: 15px;
    order: 2;
  }
  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
  }
  .text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Contacts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: inline-block;
  }
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f2f2f2;
  }
`;

const SocialLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: inline-block;
  }
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f2f2f2;
  }
`;
