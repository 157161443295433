// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBA1e_w8HQGp8heko_lo1FGiGmuKrBEjBw",
  authDomain: "alaiku-674df.firebaseapp.com",
  projectId: "alaiku-674df",
  storageBucket: "alaiku-674df.appspot.com",
  messagingSenderId: "681976318062",
  appId: "1:681976318062:web:6664fa8a3d4730c53c3e7f",
  measurementId: "G-23C9J7S11R",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);


