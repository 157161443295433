import { useState } from 'react';
import Logo from '../../assets/logo.svg';
import { addDoc, collection } from '@firebase/firestore';
import { db } from '../../firebase/firebaseConfig';

function DeletedRequest() {
  const [data, setData] = useState({ number: '', comment: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setData({ number: '', comment: '' });
  };

  const sendComment = async (e) => {
    e.preventDefault();
    const { number, comment } = data;

    if (number && comment) {
      try {
        await addDoc(collection(db, 'comments'), { number, comment });
        resetForm();
      } catch (error) {
        console.error('Error: ', error);
      }
    } else {
      alert('Fill all fields');
      resetForm();
    }
  };

  return (
    <div className='w-full min-h-screen relative'>
      <div className='absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2 md:max-w-[50%] w-full p-4 rounded-lg flex flex-col gap-6'>
        <div className='w-64 h-64 mx-auto mb-4'>
          <img
            src={Logo}
            alt='*'
            className='rounded-xl mx-auto w-full h-full'
          />
        </div>
        <div className='w-full flex justify-between items-center'>
          <p className='text-lg text-[#333] font-semibold'>
            Запрос на удаление персональных данных
          </p>
        </div>
        <form className='grid grid-cols-1 gap-4'>
          <p>
            Чтобы отправить запрос на удаление персональных данных, заполните
            форму ниже, и наши менеджеры свяжутся с вами в ближайшее время.
          </p>
          <input
            placeholder='Номер телефона'
            type='number'
            name='number'
            className='w-full border-2 p-4 rounded-lg outline-none'
            onChange={handleChange}
            value={data.number}
          />
          <textarea
            className='w-full border-2 h-40 border-gray-300 p-4 rounded-lg resize-none outline-none'
            placeholder='Комментарий'
            name='comment'
            value={data.comment}
            onChange={handleChange}
          ></textarea>
          <button
            className='w-full bg-blue-500 rounded-lg p-4 text-white'
            onClick={sendComment}
          >
            Отправить
          </button>
        </form>
      </div>
    </div>
  );
}

export default DeletedRequest;
