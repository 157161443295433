import React, { useEffect, useState } from 'react';
import MilkProducts from './MilkProducts';
import ColdDrinks from './ColdDrinks';
import NationalProducts from './NationalProducts';
import { fetchProducts } from '../../../api/api';

const Products = () => {
  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    const { data, success } = await fetchProducts().then();
    if (success) {
      setProducts(data.data?.results);
    }
  };

  const milkProducts = products?.filter((product) => product?.category === 1);
  const nationalProducts = products?.filter(
    (product) => product?.category === 2
  );
  const coldDrinkd = products?.filter((product) => product?.category === 3);

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <MilkProducts products={milkProducts} />
      <NationalProducts products={nationalProducts} />
      <ColdDrinks products={coldDrinkd} />
    </div>
  );
};

export default Products;
