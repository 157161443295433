import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutCompany from "../AboutCompany/AboutCompany";
import Home from "../Home/Home";
import Vacancies from "./../Vacancies/Vacancies";
import Blogs from "./../Blogs/Blogs";
import Blog from "./../Blog/Blog";
import DeletedRequest from "../delete-request/DeletedRequest";

const PageRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/del-req" element={<DeletedRequest />} />
        <Route path="/" element={<Home />} />
        <Route path="/about-company" element={<AboutCompany />} />
        <Route path="/vacancies" element={<Vacancies />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:id" element={<Blog />} />
      </Routes>
    </BrowserRouter>
  );
};

export default PageRoutes;
