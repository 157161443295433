import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from './../../components/Layout/Layout';
import { fetchVacancies } from './../../api/api';
import { ReactComponent as Phone } from '../../assets/filled_phone.svg';
import { ReactComponent as Envelop } from '../../assets/filled_envelop.svg';
import Accordion from '../../components/Accordtion/Accordtion';
import Loader from '../../components/Loader';

const Vacancies = () => {
  const [loading, setLoading] = useState(false);

  const [vacancies, setVacancies] = useState([]);

  const getVacancies = async () => {
    setLoading(true);
    const { data } = await fetchVacancies().then();
    setVacancies(data.data?.results);
    setLoading(false);
  };

  useEffect(() => {
    getVacancies();
  }, []);

  if (loading) return <Loader />;

  return (
    <Layout>
      <Wrapper>
        <h1 className='mb-6'>Вакансии</h1>
        <Content>
          <div className='mr-10'>
            <p className='mb-4'>
              Здесь вы можете найти объявления о свободных вакансиях в компании
            </p>
            <p>
              Если Вы не нашли подходящей вакансии, отправьте нам резюме с
              указанием позиции по одному из указаных контактов:
            </p>
            <div className='flex mt-7'>
              <Phone />
              <a href='tel:0(220)505001' className='ml-2'>
                0(220) 50 50 01
              </a>
            </div>
            <div className='flex mt-7'>
              <Envelop />
              <a href='mailto:0(220)505001' className='ml-2'>
                hr.alaiku@gmail.com
              </a>
            </div>
          </div>
          <div className='mt-10 lg:mt-0 xl:mt-0 md:mt-0'>
            {vacancies?.map((item) => {
              return <Accordion data={item} key={item?.id} />;
            })}
          </div>
        </Content>
      </Wrapper>
    </Layout>
  );
};

export default Vacancies;

const Wrapper = styled.div`
  padding: 40px 144px 40px;
  @media (max-width: 768px) {
    padding: 15px;
  }
  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  @media (max-width: 768px) {
    display: block;
  }
`;
