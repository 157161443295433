import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { dateFormat } from '../../utils/dateFormat';

function Accordion({ data }) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {}, [height]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? '0px' : `${content.current.scrollHeight}px`);
  }

  return (
    <Wrapper>
      <div className='accordion__section'>
        <div
          className={`accordion ${active ? 'active' : ''}`}
          onClick={toggleAccordion}
        >
          <div>
            <p
              className='accordion__title'
              dangerouslySetInnerHTML={{ __html: data.jobtitle }}
            ></p>
            <p className='accordion__date'>{dateFormat(data.date)}</p>
          </div>
          <span style={{ marginLeft: '20px' }}>{active ? '-' : '+'}</span>
        </div>
        <div
          ref={content}
          style={{ maxHeight: `${height}` }}
          className='accordion__content'
        >
          <HiddenContent>
            <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
          </HiddenContent>
        </div>
      </div>
    </Wrapper>
  );
}

export default Accordion;

const Wrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

  /* Style the accordion section */
  .accordion__section {
    display: flex;
    flex-direction: column;
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    margin-bottom: 20px;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    background-color: #ccc;
  }

  /* Style the accordion content title */
  .accordion__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #00497a;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }
  .accordion__date {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
`;

const HiddenContent = styled.div`
  .requirements {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  li {
    list-style-type: circle;
  }
`;
