import { axiosInstance } from "./axiosInstance";

export const fetchSlider = async () => {
  try {
    const response = await axiosInstance.get(`slider/`);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error.data };
  }
};

export const fetchNews = async () => {
  try {
    const response = await axiosInstance.get(`news/`);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error };
  }
};
export const fetchSingleNews = async (id) => {
  try {
    const response = await axiosInstance.get(`news/${id}/`);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error };
  }
};

export const fetchVideos = async () => {
  try {
    const response = await axiosInstance.get(`video/`);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error };
  }
};

export const fetchVacancies = async () => {
  try {
    const response = await axiosInstance.get(`job/`);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error };
  }
};
export const fetchProducts = async () => {
  try {
    const response = await axiosInstance.get(`webproducts/`);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error };
  }
};

export const fetcSliders = async () => {
  try {
    const response = await axiosInstance.get(`slider/`);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error };
  }
};
export const postMessage = async (data) => {
  try {
    const response = await axiosInstance.post(`messages/`, data);
    return { success: true, data: response };
  } catch (error) {
    return { success: false, data: error };
  }
};
