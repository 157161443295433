import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MainSlider1 from '../MainSliders/MainSlider1';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import LeftArrow from '../../assets/slide-arrow-left.png';
import RightArrow from '../../assets/slide-arrow-right.png';
import { fetcSliders } from './../../api/api';
import Loader from '../Loader';

const MainSlider = () => {
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNews = async () => {
    setLoading(true);
    const { data, success } = await fetcSliders().then();
    if (success) {
      setLoading(false);
      setSliders(data.data?.results);
    }
    setLoading(false);
  };

  useEffect(() => {
    getNews();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      {sliders.length < 1 ? (
        ''
      ) : (
        <Wrapper>
          <Swiper
            slidesPerView={1}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation, Pagination]}
            className='mySwiper'
            navigation={true}
          >
            {sliders?.map((item) => {
              return (
                <SwiperSlide key={item?.id}>
                  <MainSlider1 item={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Wrapper>
      )}
    </>
  );
};

export default MainSlider;

const Wrapper = styled.div`
  padding-top: 26px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
  /* min-height:600px; */
  .swiper-slide.swiper-slide-active {
    transform: scale(1.2);
  }
  .swiper.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.mySwiper {
    padding: 30px 0;
    position: relative;
    @media (max-width: 768px) {
      padding: 5px 0;
    }
  }
  .swiper-button-prev {
    background-color: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 52.5%
    );
    background-image: url(${LeftArrow});
    z-index: 9999;
    position: absolute;
    width: 85px;
    height: 180px;
    left: 0px;
    top: 35%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    @media (max-width: 768px) {
      display: none;
    }
    :after {
      display: none;
    }
  }
  .swiper-button-next {
    background-image: url(${RightArrow});

    z-index: 9999;
    position: absolute;
    width: 75px;
    height: 180px;
    right: 0px;
    top: 35%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    @media (max-width: 768px) {
      display: none;
    }
    :after {
      display: none;
    }
  }

  .mySwiper {
    position: relative;
    z-index: 1000;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    filter: drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.04));
    border-radius: 30px;
    background-color: #fff;
    padding: 10px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
