import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FilterButtons from './FilterButtons';
import Slider from './../Slider/Slider';
import { fetchProducts } from '../../api/api';
import ReactLoading from 'react-loading';

const Products = () => {
  const [isloading, setIsloading] = useState(false);
  const [activeBtn, setActiveBtn] = useState({
    milk: true,
    national: false,
    cold: false,
  });

  const [backgroundColor, setBackgroundColor] = useState(
    'linear-gradient(178.82deg, #A2C8F1 0.96%, #DDEDFC 98.96%)'
  );
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(1);

  const blue = 'linear-gradient(178.82deg, #A2C8F1 0.96%, #DDEDFC 98.96%)';
  const purple = 'linear-gradient(178.82deg, #F7E1E1 0.96%, #FFF1DB 98.96%)';
  const green = 'linear-gradient(178.82deg, #C4EEBD 0.96%, #F2E9CA 98.96%)';

  const handleSwitchActiveBtn = (value) => {
    let backgroundColor;
    let activeBtn = {
      milk: false,
      national: false,
      cold: false,
    };

    switch (value) {
      case 1:
        backgroundColor = blue;
        activeBtn.milk = true;
        break;
      case 2:
        backgroundColor = purple;
        activeBtn.national = true;
        break;
      case 3:
        backgroundColor = green;
        activeBtn.cold = true;
        break;
      default:
        backgroundColor = null;
        break;
    }

    setBackgroundColor(backgroundColor);
    setActiveBtn(activeBtn);
    setCategory(value);
  };

  const getProducts = async () => {
    setIsloading(true);
    const { data, success } = await fetchProducts().then();
    if (success) {
      setProducts(data.data?.results);
      setIsloading(false);
    }
    setIsloading(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    setIsloading(true);

    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));

      const filtered = products.filter((item) => item.category === category);
      setFilteredProducts(filtered);
      setIsloading(false);
    };

    fetchData();
  }, [category, products]);

  const renderText = () => {
    const textMap = {
      milk: {
        title: 'Молочная продукция',
        description:
          'Без крахмала и ЗМЖ. Только натуральное. Можете проверить!',
      },
      national: {
        title: 'Национальные напитки',
        description: 'Уникальность и натуральность - наши главные ценности!',
      },
      cold: {
        title: 'Прохладительные напитки',
        description: 'Дары природы в наших напитках без добавлений сахара!',
      },
    };

    const { title, description } =
      textMap[Object.keys(activeBtn).find((key) => activeBtn[key])];

    return (
      <div>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    );
  };

  return (
    <Wrapper style={{ background: backgroundColor }} id='products'>
      <FilterButtons
        activeBtn={activeBtn}
        handleClick={handleSwitchActiveBtn}
      />
      <TextContent>{renderText()}</TextContent>
      {isloading ? (
        <div className='flex justify-center items-center h-80'>
          <ReactLoading type='spin' />
        </div>
      ) : (
        <Slider products={filteredProducts} />
      )}
    </Wrapper>
  );
};

export default Products;

const Wrapper = styled.div`
  min-height: 716px;
  padding: 60px 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextContent = styled.div`
  text-align: center;
  margin: 50px 0;

  h1 {
    font-weight: 600;
    font-size: 62px;
    line-height: 70px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 32px;
      width: 170px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #231f20;
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 0 15px;
    }
  }
`;
