import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const ModalComponent = ({ closeModal, modalIsOpen, data }) => {
  let subtitle;
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: '80%',
      padding: '0',
    },
    overlay: { zIndex: 1000 },
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <ReactPlayer
          className='react-player '
          url={data}
          width='100%'
          height='100%'
          controls={true}
        />
      </Modal>
    </div>
  );
};

export default ModalComponent;
