import React, { useState } from 'react';
import styled from 'styled-components';
import { postMessage } from '../../api/api';

const FooterContactForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      email,
      fullname: fullName,
      phone: phoneNumber,
    };
    const { success } = await postMessage(dataToSend);
    if (success) {
      alert('Успешно отправлено !!! ✅✅');
      setFullName('');
      setEmail('');
      setPhoneNumber('');
    } else {
      alert('Возникло ошибко при отправке !!!😑');
    }
  };

  return (
    <FormWrapper>
      <form className='py-7 px-5 text-center' onSubmit={handleSubmit}>
        <p className='text'>
          Оставьте свои контактные данные и мы свяжемся с вами{' '}
        </p>
        <div className='mb-5 text-center'>
          <input
            placeholder='Ваше ФИО'
            required
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className='mb-5 text-center'>
          <input
            placeholder='Ваш e-mail'
            type='email'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='mb-5 text-center'>
          <input
            placeholder='Номер телефона'
            type='text'
            required
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <BtnWrap>
          <button type='submit'>Отправить</button>
        </BtnWrap>
      </form>
    </FormWrapper>
  );
};

export default FooterContactForm;

const FormWrapper = styled.div`
  margin: 0 auto;
  @media (max-width: 768px) {
    order: 1;
  }
  form {
    background: #ffffff;
    border-radius: 30px;
    max-width: 400px;
    @media (max-width: 768px) {
      max-width: 350px;
      margin-bottom: 40px;
    }
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }
  input {
    background: #f2f2f2;
    border-radius: 10px;
    padding: 10px 15px;
  }
`;

const BtnWrap = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #00497a;
  border-radius: 30px;
  display: inline-block;
  padding: 10px 20px;
  margin: 0 auto;
  cursor: pointer;
`;
