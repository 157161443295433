import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { fetchVideos } from '../../api/api';
import ModalComponent from '../Modal/Modal';
import { BsPlayCircle } from 'react-icons/bs';
import Loader from '../Loader';

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [size, setSize] = useState(window.innerWidth);

  const openCloseModal = (data) => {
    setModalData(data);
    setModalIsOpen(!modalIsOpen);
  };

  const getVideos = async () => {
    setLoading(true);
    const { data } = await fetchVideos().then();
    setVideos(data.data?.results);
    setLoading(false);
  };

  useEffect(() => {
    getVideos();
  }, []);

  window.addEventListener('resize', function (e) {
    setSize(window.innerWidth);
  });

  if (loading) return <Loader />;

  return (
    <VideoContent>
      <h1>Видео о нас</h1>
      <VideoItems>
        <ModalComponent
          modalIsOpen={modalIsOpen}
          closeModal={openCloseModal}
          data={modalData}
        />
        <Swiper
          slidesPerView={size <= 1024 ? 1 : 2}
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation]}
          className='mySwiper'
          navigation={true}
        >
          {videos?.length ? (
            videos?.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Thumbnail
                    className='video'
                    key={index}
                    onClick={() => openCloseModal(item?.video)}
                  >
                    <BsPlayCircle />
                    <img src={item?.thumbnail} alt='img' />
                  </Thumbnail>
                </SwiperSlide>
              );
            })
          ) : (
            <p className='text-center font-bold text-2xl text-blue-900'>
              "Видео отсутствует !!!"
            </p>
          )}
        </Swiper>
      </VideoItems>
    </VideoContent>
  );
};

export default Videos;

const Thumbnail = styled.div`
  width: 500px;
  height: 280px;
  border: 1px solid #fff;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    color: white;
    font-size: 3em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: black;
    padding: 20px 0;
  }
`;

const VideoContent = styled.div`
  margin: 0 144px;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin: 0;
    margin-bottom: 30px;
  }
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 30px;
  }
`;

const VideoItems = styled.div``;
